import React from 'react'
import { Link } from 'gatsby'
import { Button } from '../components'


// const PreFooter = ({ cta, url }) => {
const PreFooter = (props) => {
  return (
    <section className="dark-grey-section why-bear" id="rfi">
      <div className="wrapper narrow cols">
        <div className="col-70">
          <h3>Why Bear University</h3>
          <p>
            Here, you’ll have access to all the resources of a large public
            research university—and the personalized attention that makes you
            feel like part of a tight-knit community. This combination is why
            we’re
            <Link to="/"> nationally recognized for excellence</Link> and why
            our <Link to="/">alumni outcomes</Link> can’t be beat.
          </p>
          <p>
            <strong>{props.callout}</strong>
          </p>
          <Button
            variant="solid"
            color="orange"
            onClick={() => {
              if (props.url !== undefined) {
                return window.open(props.url);
              }
            }}
          >
            {props.cta}
          </Button>
        </div>     
      </div>
    </section>
  );
}

export default PreFooter
